import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ( imageName ) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      office: file(relativePath: { eq: "office.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      headerLogo: file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      },
      janJoh: file(relativePath: { eq: "jan.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      officeOut: file(relativePath: { eq: "office_out.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ufo: file(relativePath: { eq: "ufo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  if(imageName.fluid && 'office' === imageName.fluid) {
    return <Img fluid={data.office.childImageSharp.fluid} alt={'Office'}/>
  }

  if(imageName.fluid && 'janJoh' === imageName.fluid) {
    return <Img fluid={data.janJoh.childImageSharp.fluid} alt={'Jan Johansson'}/>
  }

  if(imageName.fluid && 'officeOut' === imageName.fluid) {
    return <Img fluid={data.officeOut.childImageSharp.fluid} alt={'Pihakuva'}/>
  }

  if(imageName.fixed && 'headerLogo' === imageName.fixed) {
    return <Img fixed={data.headerLogo.childImageSharp.fixed} alt={'Logo'} />
  }

  if(imageName.fluid && 'ufo' === imageName.fluid) {
    return <Img fluid={data.ufo.childImageSharp.fluid} alt={'404'} />
  }

  if(imageName.fluid && 'linkedin' === imageName.fluid) {
    return <Img fluid={data.linkedin.childImageSharp.fluid} alt={'linkedin'} />
  }

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default Image
