import React from 'react'
import { Link } from 'gatsby'
import { FaPhoneAlt, FaRegEnvelope, FaAt } from 'react-icons/fa';

const clickMailto = () => {
    const n = "info";
    const d = "softtile.fi";
    const mailto_link = 'mailto:' + n + "@" + d;
    window.location = mailto_link;
}

const Footer = class extends React.Component {
    render() {
        return (
            <footer className="footer has-text-white-ter footer-content">
                <div
                    style={{
                        margin: `0 auto`,
                        maxWidth: 1170,
                        padding: `0.45rem 1.0875rem`,
                    }}
                >
                    <div className="content has-text-white-ter">
                        <div className="has-text-white-ter">
                            <div className="columns">
                                <div className="column is-4">
                                    <section className="menu is-pulled-left">
                                        <ul className="menu-list">
                                            <li><h2>Ota yhteyttä, jutellaan lisää!</h2></li>
                                            <li>
                                                <div>
                                                    <a className={'phone'} href={'tel:+358505410701'}><FaPhoneAlt /> +358 50 5410 701</a>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <button className={'mail'} onClick={() => {clickMailto()}}><FaRegEnvelope /> info<FaAt style={{fontSize: '13px'}}/>softtile.fi</button>
                                                </div>
                                            </li>
                                            <li><div><p style={{ padding: '10px 10px'}}>Y-tunnus: 2534216-2</p></div></li>
                                        </ul>
                                    </section>
                                </div>
                                <div className="column is-8 footer-address">
                                    <div className="content">
                                        <div style={{fontSize: '1.25rem'}}>Tervajoki (Vaasa)</div>
                                        <div style={{color: '#a3a3a3'}}>Tervajoentie 6</div>
                                        <div style={{color: '#a3a3a3'}}>66440 Tervajoki</div>
                                    </div>
                                    <section>
                                        <ul className="menu-list footer-menu-list">
                                            <li>
                                                <Link className="navbar-item" to="/">
                                                    Etusivu
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="navbar-item" to="/services">
                                                    Palvelut
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="navbar-item" to="/company">
                                                    Yritys
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="navbar-item" to="/contact">
                                                    Ota yhteyttä
                                                </Link>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
