import PropTypes from "prop-types"
import React from "react"
import Navbar from "./Navbar";

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#093356`,
      marginBottom: `1.45rem`,
      borderColor: '#080808',
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1170
      }}
    >
        <Navbar/>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
