import React from 'react'
import { Link } from 'gatsby'
import Image from "../components/image"

const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                        navBarActiveClass: 'is-active',
                    })
                    : this.setState({
                        navBarActiveClass: '',
                    })
            }
        )
    }

    render() {
        return (
            <nav
                className="navbar is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item" title="SoftTile" style={{ borderBottom: 'none', marginLeft: '-15px' }}>
                            <Image fixed={'headerLogo'} />
                        </Link>
                        {/* Hamburger menu */}
                        <div role={'navigation'}
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className="navbar-end has-text-centered">
                            <Link className="navbar-item" to="/"  activeClassName="navbar-active">
                                ETUSIVU
                            </Link>
                            <Link className="navbar-item" to="/services" activeClassName="navbar-active">
                                PALVELUT
                            </Link>
                            <Link className="navbar-item" to="/company" activeClassName="navbar-active">
                                YRITYS
                            </Link>
                            <Link className="navbar-item" to="/contact" activeClassName="navbar-active">
                                OTA YHTEYTTÄ
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
